/* General styles that apply across all devices */
body {
    margin: 0;
    font-family: 'Noah-Regular', sans-serif!important;
      color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white!important;
    /* background-image: url('./assets/images/Lines and Arrows_Top arrows.svg'); */
  }
  
  .bold-text {
    font-family: 'Noah-Bold', sans-serif;
  }
  
  .italic-text {
    font-family: 'Noah-BoldItalic', sans-serif;
  }
  
  .regular-italic-text {
    font-family: 'Noah-RegularItalic', sans-serif;
  }
  

:root {
    --my-box-shadow: 0 2px 6px #95989A;
}

* {
    margin: 0;
    padding: 0;
}

.row {
    margin-right: 0!important;
    margin-left: 0!important;
}

h1 {
    font-size: 3vw;
}

.container-width {
    width: 80%;
}

/* ... other desktop-specific styles ... */

/* Nav */
.logo {
    height: 100%;
    width: 100%;
    margin-right: auto;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* BUTTONS */

.btn-primary {
    background: #14555E;
    border: 1px solid #fff;
    border-radius: 125px;
    width: auto;
    height: 45px;
    transition: all 400ms;
}

.btn-primary:hover {
    background: #fff;
    /* border: 1px solid #14555E; */
    color: #14555E;
    border-radius: 125px;
    width: auto;
    height: 45px;
    box-shadow: var(--my-box-shadow);
}

button.btn.btn-primary {
    position: absolute;
    top: 18%;
    right: 9.55%;
    font-size: 15px;
    height: 30px;
    line-height: 0;
    transition: all;
}

.btn.btn-secondary {
    background: #EE6233;
    border: 1px solid #fff;
    border-radius: 125px;
    width: auto;
    height: auto;
    box-shadow: var(--my-box-shadow);
    color: #fff;
    padding: 3%;
}

.btn.btn-secondary:hover {
    background: #fff;
    /* border: 1px solid #14555E; */
    color: #EE6233;
    border-radius: 125px;
    width: auto;
    height: auto;
    box-shadow: var(--my-box-shadow);
}

.big-btn {
    background: linear-gradient(180deg, #38634f, #316152);
    border: none;
    border-radius: 125px;
    max-width: calc(400px + 10vw);
    box-shadow: var(--my-box-shadow);
    color: #fff;
    padding: 4%;
    font-size: calc(25px + 2vmin);
    text-align: center;
    
}

/* HOME HERO */

.hero-bg {
    background-image: url('./assets/images/stairway.jpg');
    background-size: cover;
    background-position: center;
    height: 600px;
    max-height: 600px;
}

.hero-overlay {
    background: linear-gradient(179deg, #285852c9, #205959);
    z-index: 1; /* Ensure the overlay is above the background but below the content */
}

.hero-bg .container {
    z-index: 2; /* Ensure the content is above the overlay */
    top: 15%;
}

.prime-cta {
    color: white;
    position: relative;
    top: 4rem;
}

.p-sub-copy {
    font-size: 20px;
}

/* BACKGROUND CSS */

.btf-bg {
    background-image: url('./assets/images/Lines and Arrows_Top arrows.svg');
    margin: -3% 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 117vh;
    width: 100%;
    /* background-blend-mode: multiply; */
}

.about-bg {
    background-image: url('./assets/logos/Hera Logo_Main Full Color_sansTitle.png');
    background-color: #e8a400;
    background-repeat: no-repeat;
    background-position: center!important;
    background-size: 88vh;
    width: 100%;
    background-blend-mode: lighten;
    position: relative;
    z-index: 10;

}

.five-arrow-bg {
    background-image: url('./assets/images/Lines and Arrows_Bottom arrows.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 120vh;
    padding-top: 200px;
}

.faq-bg {
    background-image: url('./assets/images/Walnut-Hill-Blog-Photo-Template-5-1024x576.jpg');
    background-size: cover;
    background-position: center;
    padding: 200px 0;
}

.bush_leaves {
    background-image: url('./assets/images/bushes-of-leaves-svgrepo-com.png');
    padding-bottom: 20vw;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 40%;

}

.leaves-2 {
    background-image: url('./assets/images/leaves-2-svgrepo-com.png');
    height: 42vw;
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: center top;

}

.services-bg {
    background-image: url('./assets/images/priscilla-du-preez-K8XYGbw4Ahg-unsplash.2000.jpg');
    background-size: cover;
    background-position: center;
    padding: 200px 0;
}

/* CARDS */

.card {
    min-height: 525px;
    padding: 4% 0;
}

.card-roles {
    display: flex;
    justify-content: center;
}

.card-img-size {
    height: auto;
    width: 40%!important;      
    margin: auto;

}

.demo-div {
    background: var(--unnamed-color-14555e) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-707070);
    background: #14555E 0% 0% no-repeat padding-box;
    box-shadow: var(--my-box-shadow);
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;

}

/* Home Portfolio About Hera */
.profile-image {
    max-width: 80%;
}

.profile-details {
    margin-top: 10%!important;
    margin-left: -10%;
}

/* .profile-back-1{
    width: 340px;
    height: 300px;
    background-color: #EE6233;
    border-radius: 9px 217px 9px 262px;
    transform: rotate(66deg);
}

.profile-back-2 {
    border-radius: 9px 217px 9px 262px;
    width: 300px;
    height: 415px;
    background-color: #14555e;
    transform: rotate(277deg);
}*/

.profile-name {
    padding: 0 0 1rem 0;
    color: #ffffff;
}

.profile-copy {
    font-size: 22px;
    color: #14555e;

}

.lcsw {
    padding: 0 0 1rem 0;
    color: #ffffff
}

/* FAQS */

.accordion, .accordion-item, .accordion-header {
    background-color: #ffffff69!important;
    border-radius: 5px;
}

.accordion-item {
    border: 1px solid #14555e7a!important;
}

.accordion-body {
    font-size: 1.5rem!important;
}

.accordion-button {
    font-size: 1.5rem!important;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background-color: #14555e!important;
    color: #f6dc99!important;
    box-shadow: 0 0 5px #f6dc99, 0 0 25px #f6dc99, 0 0 50px #f6dc99;
    
}

.mini-faqs {
    padding-top: 0;
    padding-bottom: 12%;

}

/* PREFOOTER */

.pre-footer {
    background-color: #EE6233;
    color: #F6DC99;
    border-radius: 60% 60% 0 0;
    box-shadow: 0 0 5px #faee76, 0 0 25px #faee76, 0 0 50px #faee76, 0 0 100px #faee76, 0 0 200px #faee76, 0 0 300px #faee76;
 }

 .pre-footer-title {
    font-size: calc(25px + 1vmin);
    padding: 0 10% 2%;
 }

.pre-footer-copy {
    max-width: 800px;
    margin: auto;
    font-size: 20px;
    padding: 5% 0 10%;
}

.pre-footer .btn.btn-secondary:hover {
    box-shadow: 0 0 5px #faee76, 0 0 25px #faee76, 0 0 50px #faee76, 0 0 100px #faee76;
    border: 1px solid transparent;
}

/* FOOTER   */

.pre-footer .footer {
    background-color: #14555e;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0!important;
    padding-left: 0!important;
}

.footer ul {
    padding-left: 0;
}

.footer ul li {
    float: left;
}


.footer li {
    font-size: calc(12px + 1vmin);
    padding: 1rem 1rem 1rem;

}

.footer li a {
    text-decoration: none;
    color: #ffffff;
}

.footer li a.active{
    color: #ee6233;
}

.footer img {
    padding-bottom: 1rem;
    width: 100%;
}

/* ABOUT HERA PAGE */
.about-design {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 5px;
    grid-template-rows: auto;
    padding: 2% 2% 5%;
}

.about-design img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.about-copy {
    padding: 10% 12%;
    background-color: #14555e;
    color: #fff;
    grid-column: 1/5;
    grid-row: 1/4;
    display: grid;
    align-items: center;   
}

.about-copy p {
    font-size: 20px;
}

.about-img-1 {
    grid-column: 5/8;
    grid-row: 1;
}

.about-img-2 {
    grid-column: 8/12;
    grid-row: 1;
}

.about-img-3 {
    grid-column: 5/9;
    grid-row: 2/4;
}

.about-img-4 {
    grid-column: 9/12;
    grid-row: 2;
}

.about-img-7 {
    grid-column: 9/12;
    grid-row: 3;
}

.about-copy-alison-mobile {
    display: none;
}

.about-copy-alison {
    grid-column: 5 / 12;
    grid-row: 1;
    padding: 7% 9%;
    background-color: #14555e;
    color: #fff;
    display: grid;
    align-items: center;
    font-size: 20px;
}

.exp-copy-alison {
    grid-column: 1 / 8;
    grid-row: 2;
    padding: 7% 9%;
    background-color: #14555e;
    color: #fff;
    display: grid;
    align-items: center;
    font-size: 20px;
}

.alison-profile-image {
    grid-column: 1/5;
}

.coffee-chat-image {
    grid-column: 8/12;
    grid-row: 2;
}

/* HOME SERVICES CARDS */

.hr-tag-30 {
    width: 11rem;
    justify-content: center;
    display: inline-flex;
    border: 3px solid #f6dc99;
    border-radius: 8px;
}

.hr-tag-50   {
    width: 11rem;
    justify-content: center;
    display: inline-flex;
    border: 3px solid #14555e;
    border-radius: 8px;
}

/* SERVICES PAGE */

.services {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 5px;
    row-gap: 5vh;
    /* grid-template-rows: repeat(3, 300px);
    padding: 2% 2% 5%; */
}

.serv-copy {
    font-size: 20px;
}

.services img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: var(--my-box-shadow);
}

.services-img-1 {
    grid-column: 2/7;
    grid-row: 1;
    
}

.services-intro {
    grid-column: 7/11;
    grid-row: 1;
    font-size: 20px;
    background: #14555ee0;
    border-radius: 8px;
    margin-left: -30%;
    color: #fff;
    align-self: center;
    padding: 5%;
    margin-top: 8%;
    margin-bottom: 8%;
    font-size: 2rem;

}

.services .services-ind {
    background-color: #ee6233b0;
    padding: 3%;
    grid-column: 7/11;
    grid-row: 1;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: var(--my-box-shadow);
    margin-left: -30%;
    z-index: 10;
    margin-top: 10%;
    margin-bottom: 10%;
    padding: 5%;
}

.services-ind-img {
    grid-column: 2/7;
    grid-row: 1;
}

.services-ind .serv-title,
.services-perinatal .serv-title {
    font-size: 2.25rem;
}

.services-perin-img {
    grid-column: 6/11;
    grid-row: 2;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: var(--my-box-shadow)
}

.services .services-perinatal {
    grid-column: 2/6;
    grid-row: 2;
    background-color: #14555ea6;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: var(--my-box-shadow);
    padding: 3%;
    margin-right: -30%;
    z-index: 10;
    margin-top: 10%;
    margin-bottom: 10%;
    padding: 5%;
}

.telehealth {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 5px;
}

.telehealth-bg {
    background-image: url('./assets/images/ServicesBG.jpg');
    padding: 3%;
    color: #fff;
    grid-column: 1/13;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
}

.telehealth .serv-title {
    font-size: 2.25rem;
    text-align: left;
    padding-bottom: 1%;
    grid-column: 1/13;
    grid-row: 1;
}

.telehealth .serv-copy {
    font-size: 2rem;
    padding-top: 2%;
    grid-column: 1/11;
}

.services.container {
    padding-top: 5%;
    padding-bottom: 5%;
}

/* RESOURCES PAGE */
.resource-links {
    font-size: calc(1px + 3vmin);
    padding: 10% 2% 15%;
}

.psi-logo {
    width: 10%;
}

.services-psi-logo {
    height: 10%!important;
    width: 10%!important;
    border-radius: 0px!important;
    box-shadow: none!important;
    object-fit: contain!important;
}

