.navbar-collapse {
    flex-grow: 0 !important;
}

.nav-style {
    color: #14555E !important;
    font-size: 20px !important;
}

.navbar {
    /* border: 1px solid #95989A; */
    box-shadow: var(--my-box-shadow);
}

.navbar-toggler {
    font-size: calc(5px + 3vmin) !important;
}