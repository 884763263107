@media (min-width: 1922px) {
    .btf-bg {
        background-size: calc(90vh + 5%);
    }
}

@media (max-width: 1921) {
    .btf-bg {
        background-size: 100vh;
    }
}
@media (max-width: 1200px) {
    /* .logo img {
        width: 80%;
    } */

    .navbar>.container {
        justify-content: inherit;
        display: inherit;
        max-width: 95%; 
    }

    .navbar-nav,
    button.btn.btn-primary {
        position: inherit;
    }

    .prime-cta {
        color: white;
        position: relative;
        top: 1rem;
    }

    .five-arrow-bg {
        margin-top: -10%;
    }

    .about-design .container {
        max-width: 100%;
    }

}


@media (max-width: 1010px) {
    .navbar-nav,
    button.btn.btn-primary {
        position: inherit;
    }

    /* ABOUT HERA ABOVE THE FOLD */
    .about-copy {
        padding: 10% 12%;
        background-color: #14555e;
        color: #fff;
        grid-column: 1/8;
        grid-row: 1/4;
        /* overflow-y: scroll; */
    }
    
    .about-img-1 {
        grid-column: 8/12;
        grid-row: 1;
    }
    
    .about-img-2 {
        grid-column: 8/12;
        grid-row: 1;
        display: none;
    }
    
    .about-img-3 {
        grid-column: 5/9;
        grid-row: 2/4;
        display: none;
    }
    
    .about-img-4 {
        grid-column: 8/12;
        grid-row: 2;
    }
    
    .about-img-7 {
        grid-column: 8/12;
        grid-row: 3;
    }

    .services {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        gap: 5px;
        row-gap: 0;
        /* grid-template-rows: repeat(3, 300px);
        padding: 2% 2% 5%; */
    }

    .services-ind-img {
        grid-column: 1/13;
        grid-row: 1;
    }

    .services .services-ind {
        padding: 3%;
        grid-column: 1/13;
        grid-row: 2;
        color: #ffffff;
        border-radius: 8px;
        box-shadow: var(--my-box-shadow);           
        margin-left: 0;
        z-index: 10;
        margin-top: 0;
        margin-bottom: 0;
        padding: 5%;
    }

    .services-perin-img {
        grid-column: 1/13;
        grid-row: 3;
        color: #ffffff;
        border-radius: 8px;
        box-shadow: var(--my-box-shadow);
        margin-top: 10%;
    }

    .services .services-perinatal {
        grid-column: 1/13;
        grid-row: 4;
        background-color: #14555ea6;
        color: #ffffff;
        border-radius: 8px;
        box-shadow: var(--my-box-shadow);
        padding: 3%;
        margin-right: 0;
        z-index: 10;
        margin-top: 0;
        margin-bottom: 0;
        padding: 5%;
    }

    .about-bg {
        background-position: center;
        background-size: 95vh;    
    }

    .psi-logo {
        width: 25%;
    }

}

@media (max-width: 991px) {
    .about-copy-alison {
        grid-column: 7/12;
        grid-row: 1;
        padding: 7% 9%;
        background-color: #14555e;
        color: #fff;
        display: grid;
        align-items: center;
        font-size: 20px;
    }
    
    .exp-copy-alison {
        grid-column: 1/6;
        grid-row: 2;
        padding: 7% 9%;
        background-color: #14555e;
        color: #fff;
        display: grid;
        align-items: center;
        font-size: 20px;
    }
    
    .alison-profile-image {
        grid-column: 1/7;
        grid-row: 1
    }
    
    .coffee-chat-image {
        grid-column: 6/12;
        grid-row: 2;
    }
    
}

@media (max-width: 767px) {
    .navbar-brand {
        width: 68%;
    }

    .navbar-toggler {
        font-size: smaller;
        border: none;
        line-height: 2rem;
    }

    .nav-link {
        color: #14555E;
        font-size: 15px;
    }

    .hero-bg .container {
        z-index: 2; /* Ensure the content is above the overlay */
        top: 0;
        padding: 5%;
        /*Still need to align center or something here*/
    }

    .hero-bg {
        max-height: none;
        height: 100%;
        padding: 10%;
    }

    .container .row {
        padding: 5%;
    }

    .container.position-relative .logo {
        text-align: center;
    }

    .profile-image {
        max-width: 100%;
    }

    .profile-details {
        margin-top: 5%!important;
        margin-left: 0;
    }

    .about-design.container {
        max-width: 100%;
        padding: 0 0 15%;
    }

    .services-img-1 {
        grid-column: 1/13;
        grid-row: 1;
        margin-right: 10%;
        
    }

    .services-intro {
        grid-column: 1/13;
        grid-row: 2;
        font-size: 20px;
        background: #14555ee0;
        border-radius: 8px;
        margin-left: 10%;
        margin-top: -10%;
        color: #fff;
        align-self: center;
        padding: 5%;
        margin-right: 0%;
    
    }

    .telehealth .serv-title,
    .services-ind .serv-title, 
    .services-perinatal .serv-title {
        font-size: 2rem;
    }

    .telehealth .serv-copy,
    .serv-copy {
        font-size: 20px;
    }

 }

 @media (max-width: 668px) {

    .btf-bg{
        background-repeat: repeat;
        background-size: 150vw;
    }

    .footer li {
        width: 50%;
        text-align: center;
    }

    .footer li:nth-last-child(1) {
        width: 100%;
        text-align: center;
    }

    .pre-footer {
        border-radius: 225px / 150px;
    }

    .accordion {
        width: 100%;
    }

    .mini-faqs {
        padding-top: 0;
    }

    .about-design {
        grid-template-rows: auto;
    }

    .about-copy {
        padding: 4%;
        background-color: #14555e;
        color: #fff;
        grid-column: 1/12;
        grid-row: 2;
        /* overflow-y: scroll; */
        background-image: url('./assets/images/ronak-valobobhai-4qHWTuP_RLw-unsplash.jpg');
        background-position: center;
        background-size: cover;
        background-blend-mode: exclusion;
    }
    
    .about-img-1 {
        grid-column: 1/12;
        grid-row: 1;
        /* display: none; */
    }
    
    .about-img-2 {
        grid-row: 1;
        display: none;
    }
    
    .about-img-3 {
        grid-column: 5/9;
        grid-row: 2/4;
        display: none;
    }
    
    .about-img-4 {
        grid-column: 1/12;
        grid-row: 6;
        display: none;
    }
    
    .about-img-7 {
        grid-column: 1/12;
        grid-row: 7;
        display: none;
    }

    .about-copy-alison {
        display: none;
    }
    
    .exp-copy-alison {
        display: none;
    }

    .about-copy-alison-mobile {
        display: block;
        padding: 4%;
        background-color: #7dc2d278;
        font-size: 20px;
        color: #fff;
        grid-column: 1/12;
        grid-row: 2;
        /* overflow-y: scroll; */
        background-image: url('./assets/images/priscilla-du-preez-K8XYGbw4Ahg-unsplash.2000.jpg');
        background-position: center;
        background-size: cover;
        background-blend-mode: exclusion;
    }
    
    .alison-profile-image {
        grid-column: 1/12;
        grid-row: 1
    }
    
    .coffee-chat-image {
        display: none;
    }

    

 }
