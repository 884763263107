

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Noah-Regular';
  src: local("Noah-Regular"),
      url('./assets/fonts/Noah-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Noah-Bold';
  src: local("Noah-Bold"),
      url('./assets/fonts/Noah-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Noah-BoldItalic';
  src: local("Noah-BoldItalic"),
      url('./assets/fonts/Noah-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Noah-RegularItalic';
  src: local("Noah-RegularItalic"),
      url('./assets/fonts/Noah-RegularItalic.otf') format('opentype');
}

